<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='6' )
        h1.my-0 {{$t('GPT_MENU.AINOTEBOOK')}}
        v-btn.ml-3.edit-deal-btn(@click='showAddDialog' width='40' height='40' :ripple='false' color='success' depressed dark fab)
          v-icon(size='16') icon-add
    #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
      .data-area.knowledge_height.scrollbar
          v-container.upgpt-container(max-content)
            v-row.knowledges-row.d-flex.align-center.justify-start.pl-4.pt-4
              v-col(class="mt-1" cols="auto" v-for="(data, index) in aiNotebook_data" :key='data.id').pl-0.pt-0
                v-hover
                  template(v-slot:default="{ hover }")
                    v-card.rounded-card.knowledges-card-width(:class="hover ? 'knowledges-card-hover' : ''")
                          v-card-title.pa-0.pt-1 
                            div.pa-2.d-flex.align-center(width='40px')
                              v-img.ml-2(id='rounded-card' src="../assets/images/ainotebook.png" class="white--text align-end"  width="40px")
                            div.mr-3 
                          v-card-title.pa-0.d-flex.align-center
                            div.pl-1(width="50px")
                              h4.ellipsis.pl-3  {{ data.name }}
                          v-card-text.mt-0.pb-0
                            div.knowledges-card-description
                              p.multiline-two-ellipsis.t-black(:title='data.description') {{ data.description }}
                          v-divider.ma-0.mt-3.ml-3.mr-3
                          v-card-text(@click='').pb-0.knowledges-card-text
                            p.mb-2.span {{$t("GPT_KNOWLEDGE.UPDATED_BY")}} : 
                              v-avatar.knowledges-avatar-28(v-if='data.updated_by' color="aliceBlue" :title='data.updated_by.last_name+""+data.updated_by.first_name')
                                span.span(v-if="data.updated_by.usersetting.photo === ''") {{ data.updated_by.last_name }}
                                img(v-else :src="data.updated_by.usersetting.photo" :alt='data.updated_by.usersetting.last_name')
                              span(v-else) {{$t("GPT_AINOTEBOOK.DEFAULT")}}
                            div.d-flex.align-center.justify-space-between.pb-3
                              v-btn(:href='"/upgpt/AINotebook/"+data.id' width='50%' height='30' color='#18bedf' :ripple='false'  depressed  dark style='border-radius:50px')  
                                  span {{$t("GENERAL.EDIT")}} 
                              div
                                //- v-btn( icon width='24' height='24' :title='$t("GPT_KNOWLEDGE.ONOFF")')
                                //-   v-icon(size='21' style='padding-top:2px;') mdi-power
                                v-btn.ml-2( icon @click='onDelete(data.id)' width='24' height='24' :ripple='false'  depressed dark :title='$t("GENERAL.DELETE")')
                                  v-icon(size='16' color='#6c757d') icon-remove
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addDialog' width='400' content-class='statusDialog' v-if='addDialog')
      add-dialog(@emitAddDialog='onEmitAddDialog')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import PreferenceService from "@/services/PreferenceService";
import UpMeetingService from "@/services/UpMeetingService"
import addDialog from "@/components/GPT_Dialog/AiNotebook/addAiNotebook";
import i18n from '/common/plugins/vue-i18n.js';

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    addDialog
  },
  data() {
    return {
      loading:false,
      isDelete:false,
      // 
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      version: 1,
      // Data
      aiNotebook_data:[],
    };
  },
  async created() {
    this.loading = true;
    this.getVersion();
    this.getAiNotebookData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    async getAiNotebookData(){
      await UpMeetingService.getAiNotebookList().then((response)=>{
        this.aiNotebook_data = response.data;
      })
    },
    async openAiNotebook(){
      // open aiNotebook btn
    },
    onDelete(id){
      this.deleteId = id;
      this.isDelete = true;
      this.message = i18n.t("GPT_AINOTEBOOK.DELETE");
      this.messageDialog = true;
    },
    onMail: function (mail) {
      return "mailto:" + mail;

    },
    getVersion() {
    PreferenceService.getUPGPT_COPILOT().then(response => 
    {
        this.version = response.data.version;
    })
    .catch((response) => {
        this.showErrorDialog(response);
    });
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showAddDialog() {
      this.addDialog = true;
    },
    onEmitAddDialog(val){
      if(val){
        this.getAiNotebookData();
      }
      this.addDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val && this.isDelete){
          await UpMeetingService.deleteAiNotebook(this.deleteId).then((response)=>{
            if(response.data=="At least one"){
              this.showErrorDialog(i18n.t("GPT_AINOTEBOOK.DELETE_ERROR"))
            }else{
              this.aiNotebook_data = this.aiNotebook_data.filter(el=>el.id != this.deleteId);
            }
          })
      }
      this.isDelete = false;
      this.deleteId = null,
      this.messageDialog = false;
    },
  },
  watch:{
    "messageDialog":function(){
      if(!this.messageDialog){
        this.isDelete = false;
      }
    }
  }
};
</script>